import { Component, OnInit } from '@angular/core';
import { LayoutService } from './layout/layout.service';
import { Layout, Link } from './layout/layout.interface';
import { Router, Event, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Person } from './people/person.interface';
import { UsersService } from './core/users.service';
import { environment } from 'src/environments/environment';

// declare ga as a function to set and sent the events
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  initiatedRoute: boolean;
  dialog: any = { visible: false };
  loggedInUser: Person;
  legacyPortal = environment.legacyPortalBaseUrl;
  slowMovingBadgeValue: number = 0;
  sidebarLinks: Link[] = [
    // { title: 'Home', path: 'dashboard' },
    { title: 'Home', path: this.legacyPortal, external: true },
    { title: 'Quick Links', path: `${this.legacyPortal}/quicklinks`, external: true }, // EVERYONE
    // { title: 'Accounting', path: 'accounting', departments: ['accounting'] },
    { title: 'AP', path: `${this.legacyPortal}/accountspayable`, external: true }, // EVERYONE
    { title: 'Call Center', path: `${this.legacyPortal}/callCenter`, external: true, departments: ['corporate', 'call-center'], features: ['onCall'] },
    {
      title: 'Corporate Client', path: `${this.legacyPortal}/corporate-client`, external: true,
      departments: ['corporate', 'corporate-client'], features: ['tom'],
    },

    {
      title: 'Commissions', path: 'commissions', roles: ['executive'], links: [
        {
          description: 'Manager Commissions',
          title: 'Manager Commissions',
          icon: 'fad fa-money-check-edit-alt fa-7x',
          path: '/commissions/manager',
        },
      ],
    }, // EXECS
    {
      title: 'Dashboard', path: 'dashboard', departments: ['tempLogicDashboardLinks'], links: [
        {
          description: 'Metrics broken down by store and month',
          title: 'Managers Dashboard',
          image: 'assets/img/statistics.svg',
          path: '/dashboard/manager',
          features: ['managers-dashboard'],
        },
        {
          description: '',
          title: 'Salesperson Dashboard',
          image: 'assets/img/training.svg',
          path: '/dashboard/salesperson',
        },
      ],
    }, // Manager & Execs

    { title: 'Dashboard', path: 'dashboard/salesperson', departments: ['tempLogicDashboardSalesperson'] }, // Salesperson

    { title: 'Directories', path: `${this.legacyPortal}/directories`, external: true }, // EVERYONE
    { title: 'Documents', path: `${this.legacyPortal}/documents/home`, external: true }, // EVERYONE
    { title: 'HR', path: `${this.legacyPortal}/hr`, external: true, departments: ['corporate', 'hr'], features: ['att', 'checks'] },
    { title: 'Inventory', path: `${environment.inventoryManagerClientURL}`, badge: this.slowMovingBadgeValue, external: true, features: ['inventory manager'] }, // Must have Inv Man checked in portal settings
    { title: 'Fleet', path: `${this.legacyPortal}/fleet`, external: true }, // EVERYONE
    { title: 'IT', path: `${this.legacyPortal}/it/reports`, departments: ['corporate', 'hr'], external: true },
    { title: 'Marketing', path: `${this.legacyPortal}/marketing`, external: true }, // EVERYONE
    { title: 'Reports', path: `${this.legacyPortal}/reports`, external: true }, // EVERYONE
    { title: 'Resources', path: `${this.legacyPortal}/resources`, external: true }, // EVERYONE
    { title: 'Sales', path: `${this.legacyPortal}/sales`, external: true, departments: ['corporate', 'sales'], features: ['commission', 'reports'] },
    { title: 'Settings', path: `${this.legacyPortal}/settings`, external: true, departments: ['settings'] },
    { title: 'STM-U', path: `${this.legacyPortal}/university`, external: true }, // EVERYONE
  ];

  constructor(
    router: Router,
    private layoutService: LayoutService,
    private usersService: UsersService,
  ) {
    //console.log('main constructor');
    // subscribe to router events and send page views to Google Analytics
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsRouterHook(event);
      }

      if (event instanceof NavigationStart) {
        if (!this.initiatedRoute) {
          this.setInitialLink(event);
        }
      }
    });

    //console.log('main constructor end');

  }

  async ngOnInit() {

    //console.log('main init');
    this.usersService.loggedInUser$.subscribe((person: Person) => {
      if (person) {
        this.loggedInUser = person;
        const sidebarLinks = this.getUsersSidebarLinks();
        this.setupNavigation(sidebarLinks);
        this.dialogWatcher();
      }
    });

    console.log('main init end');
  }

  googleAnalyticsRouterHook(event: NavigationEnd) {
    ga('set', 'page', event.urlAfterRedirects);
    ga('send', 'pageview');
  }

  setInitialLink(event: NavigationStart): void {

    //console.log('set init link');

    const pathParts = event.url.split('/');
    if (pathParts.length === 2) {
      const link = this.sidebarLinks.find((link: any) => link.path === pathParts[1]);
      if (link) {
        this.layoutService.setActiveLink(link);
      }
      this.initiatedRoute = true;
    }
  }

  dialogWatcher() {
    this.layoutService.layout.subscribe((layout: Layout) => {
      this.dialog = layout.dialog || { visible: false };
    });
  }

  getUsersSidebarLinks(): Link[] {
    const authorizedLinks = [];
    const keycloakProfile = this.loggedInUser.contexts.keycloakProfile;
    const userAttributes = keycloakProfile.attributes;
    const userRoles = userAttributes.roles && userAttributes.roles.length ? userAttributes.roles : [];
    const userDepartments = userAttributes.departments && userAttributes.departments.length ? userAttributes.departments : [];
    const userFeatures = userAttributes.stm_features && userAttributes.stm_features.length ? userAttributes.stm_features : [];
    for (const link of this.sidebarLinks) {
      const linkRoles = link.roles || [];
      const linkDepartments = link.departments || [];
      const linkFeatures = link.features || [];
      let isAuthorized = true;
      if (linkRoles.length) {
        const intersection = linkRoles.filter(r => userRoles.includes(r));
        if (!intersection.length) {
          isAuthorized = false;
        }
      }

      if (isAuthorized && linkDepartments.length) {
        const intersection = linkDepartments.filter(d => userDepartments.includes(d));
        if (!intersection.length) {
          isAuthorized = false;
        }
      }

      if (isAuthorized && linkFeatures.length) {
        const intersection = linkFeatures.filter(f => userFeatures.includes(f));
        if (!intersection.length) {
          isAuthorized = false;
        }
      }

      if (link.path.includes('dashboard')) {
        if (this.hasAccessToDashboard(link)) {
          isAuthorized = true;
        }
      }

      if (isAuthorized) {
        authorizedLinks.push(link);
      }
    }

    return authorizedLinks;
  }

  hasAccessToDashboard(link: Link): boolean {

    //console.log('has access to dashboard');

    const keycloakProfile = this.loggedInUser.contexts.keycloakProfile;
    const loggedInUser = this.loggedInUser;
    // Dashboard logic
    if (!keycloakProfile || !loggedInUser) {
      return false;
    }
    const isManager = loggedInUser.isManager;
    const isExecutive = loggedInUser.isExecutive;
    const isTrainer = loggedInUser.isTrainer;
    const isSalesperson = loggedInUser.isSalesperson;
    const canAccessDashboardLinks = isManager || isExecutive || isTrainer;
    const isDashboardLinksRoute: boolean = link.departments.indexOf('tempLogicDashboardLinks') > -1;
    const isSalesmanDashboardRoute = !isTrainer && link.departments.indexOf('tempLogicDashboardSalesperson') > -1;

    return isSalesmanDashboardRoute && isSalesperson || isDashboardLinksRoute && canAccessDashboardLinks;

  }

  setupNavigation(sidebarLinks: any[]): void {

    //console.log('setup nav:', sidebarLinks);

    // const match = this.user.matchID && this.user.matchID._id ? user.matchID : undefined;
    // const isManager = match && match.managing && match.managing.length > 0;
    // let commissionPath = '/bad-request';

    // if (match) {
    //   commissionPath = isManager ? '/store/manager' : `/commission/rep/${this.user.matchID._id}`;
    // }

    const layout = {
      dialog: { visible: false },
      topbar: {
        links: [
          { title: 'Home', path: this.legacyPortal, external: true },
          { title: 'Quick Links', path: `${this.legacyPortal}/quicklinks`, external: true },
          { title: 'Info-Rack', path: 'https://stmtires.sharepoint.com/sites/InfoRack', external: true },
          { title: 'Support Portal', path: 'https://southerntiremart.freshservice.com/', external: true },
          // { title: 'IT Help', path: 'http://10.212.99.74', class: 'it-help', external: true },
        ],
      },
      sidebar: {
        links: sidebarLinks || [],
      },
    };

    this.layoutService.init(layout);
  }

  /*
  ** BELOW IS HOW YOU CAN SETUP THE LINKS ONCE THE MODULE/FEATURE IS IN THE APP
  */

  /* links: [
    {
      title: 'Home',
      path: 'home',
      links: [
        {
          description: 'Find the most up-to-date store and corporate directories here.',
          title: 'Directory',
          image: 'assets/img/icons/phone.svg',
          path: 'directory',
        },
        {
          description: 'Provides links to various training materials for STM employees.',
          title: 'STM-U',
          image: 'assets/img/icons/uni.svg',
          path: 'university',
        },
        {
          description: 'Useful resources including billing info, national accounts, marketing and more.',
          title: 'Info-Rack',
          image: 'assets/img/icons/Info.svg',
          path: 'https://stmtires.sharepoint.com/sites/InfoRack',
          external: true,
        },
        {
          description: 'View commissions for the current month, as well as up to 12 previous months.',
          title: 'Commission',
          image: 'assets/img/icons/commission.svg',
          feature: 'commission',
          path: commissionPath,
        },
        {
          description: 'Submit check requests, petty cash requests, expense reports, etc.',
          title: 'Reimbursements',
          image: 'assets/img/icons/money.svg',
          path: 'accountspayable/reimbursements',
        },
      ],
    },
    {
      title: 'Quick Links',
      path: 'quicklinks',
      links: [
        {
          description: 'Find the most up-to-date store and corporate directories here.',
          title: 'Directory',
          image: 'assets/img/icons/phone.svg',
          path: '/directory',
        },
        {
          description: 'Provides links to various training materials for STM employees.',
          title: 'STM-U',
          image: 'assets/img/icons/uni.svg',
          path: '/university',
        },
        {
          description: 'Useful resources including billing info, national accounts, marketing and more.',
          title: 'Info-Rack',
          image: 'assets/img/icons/Info.svg',
          path: 'https://stmtires.sharepoint.com/sites/InfoRack',
        },
      ],
    },
    {
      title: 'AP',
      path: 'accountspayable',
      links: [
        {
          description: 'Batch and verify invoices before pushing into MaddenCo.',
          title: 'Invoices',
          image: 'assets/img/icons/invoice.svg',
          path: '/accountspayable/invoices',
          feature: 'invoices',
        },
        {
          description: 'View open batches that have not yet been pushed to MaddenCo.',
          title: 'Open Batches',
          image: 'assets/img/icons/batch.svg',
          path: '/accountspayable/batches',
          feature: 'invoices',
        },
        {
          description: 'Invoice Processing Work Queue.',
          title: 'Work Queue',
          image: 'assets/img/icons/queue.svg',
          path: '/accountspayable/2025',
          feature: 'invoices',
        },
        {
          description: 'Submit check requests, petty cash requests, expense reports, etc.',
          title: 'Reimbursements',
          image: 'assets/img/icons/money.svg',
          path: '/accountspayable/reimbursements',
        },
        {
          description: 'Search for invoices by invoice #, vendor #, store #, dates and more.',
          title: 'Search',
          image: 'assets/img/icons/scope.svg',
          path: '/accountspayable/invoices/search',
          feature: 'invoices',
        },
      ],
    },
    {
      title: 'Call Center',
      departments: ['corporate', 'call-center'],
      features: ['onCall'],
      path: 'callCenter',
      links: [
        {
          description: `Manage your store's on-call service tech schedule and working hours so the Call Center
          will know who to call when a customer needs assistance.`,
          title: 'On Call',
          icon: 'fas fa-phone fa-5x',
          path: 'https://oncall.stmtires.com/',
          feature: 'onCall',
        },
      ],
    },
    {
      title: 'Corporate Client',
      departments: ['corporate', 'corporate-client'],
      features: ['tom'],
      path: 'corporate-client',
      links: [
        {
          description: `Tire Order Management system that allows fleet customers to place orders, manage work
          queues and store documents.`,
          title: 'TOM',
          image: 'assets/img/icons/tom.svg',
          feature: 'tom',
          path: 'https://tom.stmtires.com/',
          external: true,
        },
      ],
    },

    {
      title: 'Directory',
      path: 'directory',
      links: [
        {
          description: 'Company wide directory with manager contact info, addresses, etc. for each store.',
          title: 'Store Directory',
          image: 'assets/img/store/store.png',
          external: true,
          path: 'assets/directories/STM_Store_Directory.xlsx',
          onState: 'directory.home',
        },
        {
          description: 'Contact info for each department at the corporate office.',
          title: 'Corporate Directory',
          image: 'assets/img/store/corp.png',
          external: true,
          path: 'assets/directories/STM_Corporate_Directory.xlsx',
        },
        {
          description: 'Store Location Map',
          title: 'Map',
          image: 'assets/img/store/map.png',
          external: true,
          path: 'assets/directories/STM_MAP_and_Locations.pdf',
        },
      ],
    },
    { title: 'Documents', path: 'documents/explorer', links: [] },
    {
      title: 'HR',
      departments: ['corporate', 'hr'],
      features: ['att', 'checks'],
      path: 'hr',
      links: [
        {
          title: 'AT&T Discount',
          description: `Information about discounts on AT&T wireless service and devices available to STM
          employees.`,
          image: 'assets/img/icons/att.svg',
          path: `https://www.att.com/shopservlets/irulanding?iruToken=8%2FhJVZ3OxYLlSFfjW8nK%2BOG1K1v%2FIX9DDjb6Z
          vnus10w3bargEaSurOZdEz5lOmuoDYAF8YNT7osObZiZdf7Pg%3D%3D&src=MyAttSales&source=EBIRIRGBS0000000O`,
          external: true,
          feature: 'att',
        },
        {
          title: 'Checks',
          description: 'View commission checks for the current month, as well as up to 12 previous months.',
          image: 'assets/img/icons/money.svg',
          path: '/hr/checks',
          feature: 'checks',
        },
      ],
    },
    {
      title: 'Resources',
      path: 'resources',
      links: [
        {
          description: 'Employee portal for pay stubs, tax documents and benefits information.',
          title: 'Green Shades',
          image: 'assets/img/icons/greenshade.svg',
          path: 'https://www.greenshadesonline.com/',
          external: true,
        },
        {
          description: 'Need marketing materials or want to host an event? Submit a request here.',
          title: 'Marketing Request Form',
          image: 'assets/img/icons/write.svg',
          path: 'http://bit.ly/STMMarketingRequest',
          external: true,
        },
        {
          description: `Information about discounts on AT&T wireless service and devices available to STM
          employees.`,
          title: 'AT&T Discount',
          image: 'assets/img/icons/att.svg',
          path: `https://www.att.com/shopservlets/irulanding?iruToken=8%2FhJVZ3OxYLlSFfjW8nK%2BOG1K1v%2FIX9DDjb6Z
          vnus10w3bargEaSurOZdEz5lOmuoDYAF8YNT7osObZiZdf7Pg%3D%3D&src=MyAttSales&source=EBIRIRGBS0000000O`,
          external: true,
        },
        {
          description: 'Useful resources including billing info, national accounts, marketing and more.',
          title: 'Info-Rack',
          image: 'assets/img/icons/Info.svg',
          path: 'https://stmtires.sharepoint.com/sites/InfoRack',
          external: true,
        },
      ],
    },
    {
      title: 'Sales',
      departments: ['corporate', 'sales'],
      features: ['commission', 'reports'],
      path: 'sales',
      links: [
        {
          description: 'See how your store is doing compared to previous months or other stores.',
          title: 'Reports',
          image: 'assets/img/icons/report.svg',
          path: '/reports',
          feature: 'reports',
        },
        {
          description: 'View commissions for the current month, as well as up to 12 previous months.',
          title: 'Commission',
          image: 'assets/img/icons/commission.svg',
          path: commissionPath,
          feature: 'commission',
        },
      ],
    },
    { title: 'Settings', departments: ['settings'], path: 'settings/home', links: [] },
    {
      title: 'STM-U',
      path: 'university',
      links: [
        {
          description: 'Louie Munn website containing resources for training your drivers.',
          title: 'Driver Training',
          image: 'assets/img/uni.png',
          path: 'https://louiemunnservices.com/user',
          external: true,
        },
        {
          description: 'Links to various training videos to help set up your email and other IT related tasks.',
          title: 'IT Help',
          image: 'assets/img/icons/IT.svg',
          path: '/it',
        },
      ],
    },
  ],
}, */

}
